.icon-container {
    position: relative;
    width: 80%;
    margin: auto;
    margin-top: 25%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.text-l-1 {

    animation: moveUp 0.1s forwards, moveDown 0.5s 1.2s forwards;
}

.text-o-1 {

    animation: moveUp 0.1s forwards, moveDown 0.5s 1.8s forwards;
}

.text-c {

    animation: moveUp 0.1s forwards, moveDown 0.5s 2.2s forwards;
}

.text-a {

    animation: moveUp 0.1s forwards, moveDown 0.5s 2.6s forwards;
}

.text-l-2 {

    animation: moveUp 0.1s forwards, moveDown 0.5s 3s forwards;
}

.text-e {

    animation: moveUp 0.1s forwards, moveDown 0.5s 3.4s forwards;
}

.text-l-3 {

    animation: moveUp 0.1s forwards, moveDown 0.5s 3.8s forwards;
}

.text-i {

    animation: moveUp 0.1s forwards, moveDown 0.5s 4.2s forwards;
}

.text-n {

    animation: moveUp 0.1s forwards, moveDown 0.5s 4.6s forwards;
}

.text-g {

    animation: moveUp 0.1s forwards, moveDown 0.5s 5s forwards;
}

.text-o-2 {

    animation: moveUp 0.1s forwards, moveDown 0.5s 5.4s forwards;
}

.text-icon {

    animation: moveUp 0.1s forwards, moveDown 0.5s 5.6s forwards;
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes moveDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}